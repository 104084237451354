// src/pages/App/PrivacyPolicyPage/EaseSoundPrivacyPolicyPage.js
import React, { useState } from "react";
import "./PrivacyPolicyPage.scss";

const EaseSoundPrivacyPolicyPage = () => {
  const [language, setLanguage] = useState("English");

  const handleLanguageSwitch = (lang) => {
    setLanguage(lang);
  };

  return (
    <div>
      <div className="privacyPolicyPage">
        {language === "English" ? (
          <div>
            <div className="FirstRow">
              <h1>EaseSound Use Privacy Agreement</h1>
              <div>
                [{" "}
                <span onClick={() => handleLanguageSwitch("Chinese")}>
                  中文
                </span>{" "}
                |{" "}
                <span onClick={() => handleLanguageSwitch("English")}>
                  English
                </span>{" "}
                ]
              </div>
            </div>
            <p>
              We specifically remind you (the user) to carefully read the "User
              Privacy Agreement" (hereinafter referred to as "the Agreement")
              before using the EaseSound application, to ensure that you fully
              understand the terms of this Agreement. Please read carefully and
              choose to accept or reject this Agreement. Unless you accept all
              the terms of this Agreement, you do not have the right to use the
              services covered by this Agreement. Your use of the services will
              be regarded as acceptance of this Agreement and agreement to
              adhere to its terms. This Agreement stipulates the rights and
              obligations between EaseSound Development Team and users regarding
              the "EaseSound" software services (hereinafter referred to as
              "Service"). "User" refers to the individual who uses this service.
              This Agreement may be updated by the EaseSound Development Team at
              any time without further notice, and the updated Agreement terms
              will replace the original terms once published. Users can review
              the latest version of the Agreement in this APP. If a user does
              not accept the modified terms, please immediately stop using the
              services provided by the EaseSound Development Team within the
              software application. Continuing to use the services provided by
              the EaseSound Development Team will be regarded as acceptance of
              the modified Agreement.
            </p>
            <h2>1. Usage Conditions</h2>
            <p>
              Users do not need to register an account when using the EaseSound
              application; all data will be stored directly on the user's phone
              or watch. EaseSound does not collect any personal information and
              does not record user operations or access logs on any server.
            </p>
            <h2>2. User Personal Privacy Protection</h2>
            <p>
              EaseSound is committed to not collecting, storing, or sharing any
              user's personal privacy information. Even if users access the
              photo album or files, EaseSound will not record this data.
              Therefore, users do not need to worry about the leakage or misuse
              of personal information during use.
            </p>
            <h2>3. User Content Standards</h2>
            <p>
              The content created by users in the EaseSound application,
              including but not limited to audio, text, and images, is
              completely managed by the users themselves. EaseSound does not
              assume any responsibility for the content published by users.
            </p>
            <h2>4. Usage Rules</h2>
            <p>
              Any content transmitted or published by users through this service
              does not reflect or represent, nor should it be regarded as
              reflecting or representing, the views, positions, or policies of
              the EaseSound Development Team. The EaseSound Development Team
              does not assume any responsibility for this.
            </p>
            <h2>5. Age Restrictions</h2>
            <p>
              Our application is not open to minors. You must be at least 17
              years old to use this application. During use, users must confirm
              that they meet the age requirements and fully understand and agree
              to the terms of this Privacy Policy. Since we cannot determine
              whether the content of the application is suitable for all age
              groups, we recommend that parents supervise their children's
              Internet usage.
            </p>
            <h2>6. Other</h2>
            <p>
              The EaseSound Development Team solemnly reminds users to pay
              attention to the clauses in this Agreement that exonerate the
              EaseSound Development Team's liability and restrict users' rights.
              Please read carefully and consider the risks independently. Minors
              should read this Agreement in the presence of a legal guardian.
            </p>
          </div>
        ) : (
          <div>
            <div className="FirstRow">
              <h1>辰音（EaseSound）用户隐私协议</h1>
              <div>
                [{" "}
                <span onClick={() => handleLanguageSwitch("Chinese")}>
                  中文
                </span>{" "}
                |{" "}
                <span onClick={() => handleLanguageSwitch("English")}>
                  English
                </span>{" "}
                ]
              </div>
            </div>
            <p>
              在此特别提醒您（用户）在使用辰音（EaseSound）应用之前，请认真阅读本《用户隐私协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权使用本协议所涉服务。您的使用行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定辰音（EaseSound）开发团队与用户之间关于“辰音（EaseSound）”软件服务（以下简称“服务”）的权利义务。“用户”是指使用本服务的个人。本协议可由辰音（EaseSound）开发团队随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用辰音（EaseSound）开发团队提供的软件应用内的服务，用户继续使用辰音（EaseSound）提供的服务将被视为接受修改后的协议。
            </p>
            <h2>一、使用条件</h2>
            <p>
              用户在使用辰音（EaseSound）应用时，无需注册账号，所有数据将直接存储在用户的手机或手表上。辰音（EaseSound）不收集任何个人信息，也不会在服务器上记录用户的操作或访问记录。
            </p>
            <h2>二、用户个人隐私信息保护</h2>
            <p>
              辰音（EaseSound）承诺不收集、存储或共享任何用户的个人隐私信息。即使用户访问相册或文件，辰音（EaseSound）也不会记录这些数据。因此，用户在使用过程中无需担心个人信息泄露或被滥用。
            </p>
            <h2>三、用户发布内容规范</h2>
            <p>
              用户在辰音（EaseSound）应用中所创建的内容，包括但不限于音频、文字和图片，完全由用户自行管理。辰音（EaseSound）不对用户发布的内容承担任何责任。
            </p>
            <h2>四、使用规则</h2>
            <p>
              用户通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表辰音（EaseSound）开发团队的观点、立场或政策，辰音（EaseSound）开发团队对此不承担任何责任。
            </p>
            <h2>五、年龄限制</h2>
            <p>
              我们的应用不向未成年人开放。您必须至少年满17岁才能使用本应用。在使用过程中，用户需自行确认其符合年龄要求，并能够完全理解并同意本隐私政策的条款。由于我们无法确定应用内容是否适合所有年龄段，我们建议父母监督未成年人的互联网使用。
            </p>
            <h2>六、其他</h2>
            <p>
              辰音（EaseSound）开发团队郑重提醒用户注意本协议中免除辰音（EaseSound）开发团队责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EaseSoundPrivacyPolicyPage;
